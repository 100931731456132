import { mapGetters,mapActions } from "vuex"
import moment from 'moment'
export default {
    data: () => ({
        class_modal:{
            overlay: 'z-40 bg-black bg-opacity-50',
            wrapper: 'z-50 w-96 px-3 py-12',
            modal: 'bg-white shadow rounded w-full',
            body: 'p-3',
            header: 'border-b border-gray-200 p-3 rounded-t',
            footer: 'bg-gray-100 p-3 rounded-b',
            close: 'bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            closeIcon: 'fill-current h-4 w-4',
            overlayEnterClass: 'opacity-0',
            overlayEnterActiveClass: 'transition ease-out duration-100',
            overlayEnterToClass: 'opacity-100',
            overlayLeaveClass: 'opacity-100',
            overlayLeaveActiveClass: 'transition ease-in duration-75',
            overlayLeaveToClass: 'opacity-0',
            enterClass: '',
            enterActiveClass: '',
            enterToClass: '',
            leaveClass: '',
            leaveActiveClass: '',
            leaveToClass: '',
            },
        
        default_url:process.env.VUE_APP_IMAGES,
        config:{

        }

    }),

    created() {
        this.config = {
            headers:{
                'Authorization': 'Bearer ' + this.token,
            }
        }

        this.setCurrentPath(this.$route.path)
    },
    methods:{
        ...mapActions({
            setUser:'auth/setUser',
            setCurrentPath:'data/setCurrentPath',
        }),
        parseDate(date_value,format) {
            let date = moment(date_value).format(format)
            return date
        },
        getProv() {
            this.axios.get('v1/wilayah/provinces',this.config)
            .then((ress) => {
                this.province = ress.data.data
     
            })
        },
        getCity() {
            this.axios.get('v1/wilayah/city?prov_id='+this.addData.prov_id ,this.config)
            .then((ress) => {
                this.city = ress.data.data
            })
        },
        getDevice() {
            this.axios.get('v1/device',this.config)
             .then((ress) => {
                 this.device = ress.data.rows
             })
        },
        getMenu() {
            this.axios.get('v1/menu',this.config)
             .then((ress) => {
                 this.menu = ress.data.rows
             })
        },
        getRole() {
            this.axios.get('v1/role',this.config)
             .then((ress) => {
                 this.role = ress.data.rows
             })
        },
        getGroup() {
            this.axios.get('v1/group-sensor',this.config)
             .then((ress) => {
                 this.groups = [
                     {name:'All Sensor',id:''}
                 ]
                 ress.data.rows.forEach(element => {
                     this.groups.push(element)
                 });
             })
        },
        getDis() {
            this.axios.get('v1/wilayah/district?city_id='+this.addData.city_id ,this.config)
            .then((ress) => {
                this.district = ress.data.data
            })
        },
        async getDeviceParam() {
            await this.axios.get('v2/device_param',this.config)
            .then((ress) => {
                this.device_param = ress.data.rows
            })
        },

        errorResponse(err) {
            if (err.response) {
                if (err.response.status == 401) {
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                    this.setUser({user:null,token:null})
                    this.$router.push('/login')
                }
            } else {
                console.log(err)
            }
           
        },
        nextPage() {
            if (this.endNumber < this.totalRows) {
                this.currentPage++
                this.getData()
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.getData()
            }
        },
        searchData() {
            this.currentPage = 1
            this.startNumber = 1
            this.getData()
        },
        statusPh(pH) {
            if (pH <= 1 && pH < 4){ return 'Very Acidid' }
            else if (pH >= 4 && pH < 5) { return 'Acidid' }
            else if (pH >= 5 && pH < 7) { return 'Slightly Acidid' }
            else if (pH >= 7 && pH < 8) { return 'Neutral' }
            else if (pH >= 8 && pH < 10) { return 'Slightly Alkaline' }
            else if (pH >= 10 && pH < 11) { return 'Alkaline' }
            else if (pH > 11) { return 'Very Alkaline' }
        }

    },
    computed:{
        ...mapGetters({
            token:'auth/token',
            user:'auth/user',
            status_user:'auth/status',
        }),
       
    },
}